import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TblRueckfahrkatasterAufzeichnungFilterDialogComponent } from './filter_dialog/filter_dialog.component';

@Component({
	templateUrl: '../../../../common/templates/list/list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblRueckfahrkatasterAufzeichnungListComponent extends ListComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public dialogService: DialogService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAufzeichnung';
		this.name = 'MENU.AUFZEICHNUNG';
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'boolean', key: 'in_kartaster', required: true, width: 400 },
			{ type: 'numeric', key: 'fkey', required: true, width: 400 },
			{ type: 'rating', key: 'bewertung', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'text', key: 'gpx', required: false, width: 400 },
			{ type: 'text', key: 'video_url', required: false, width: 400 },
			{ type: 'text', key: 'rk_gruende', required: false, width: 400 },
			{ type: 'text', key: 'rk_gefahren', required: false, width: 400 },
			{ type: 'text', key: 'rk_massnahmen', required: false, width: 400 },
			{ type: 'text', key: 'rk_status', required: false, width: 400 }
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'rk_status', required: false, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 }
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.hasFilterDialog = true;
	}

	openFilterDialog() {
		const ref = this.dialogService.open(TblRueckfahrkatasterAufzeichnungFilterDialogComponent, {
			header: this.translate.instant('Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				console.log(filters);
				this.getFilteredEntries(filters);
			}
		});
	}

	getFilteredEntries(filters) {
		//console.log(filters);
		if( filters['breiter_350_ex'] != null && filters['breiter_350_ex'] != undefined ) {
			if(filters['breiter_350_ex'] === 0) {
				filters['breiter_350'] = false;
			} else filters['breiter_350'] = true;
		} else filters['breiter_350'] = null;
		
		if( filters['kuerzer_150_ex'] != null && filters['kuerzer_150_ex'] != undefined ) {
			if(filters['kuerzer_150_ex'] === 0) {
				filters['kuerzer_150'] = false;
			} else filters['kuerzer_150'] = true;
		} else filters['kuerzer_150'] = null;
		
		if( filters['hat_akte_ex'] != null && filters['hat_akte_ex'] != undefined ) {
			if(filters['hat_akte_ex'] === 0) {
				filters['hat_akte'] = false;
			} else filters['hat_akte'] = true;
		} else filters['hat_akte'] = null;
		//console.log(filters);

		this.loading += 1;
		this.entries = [];
		this.loadFilters = filters;
		this.crudService.getFilteredEntries(this.apiUrl, filters).then(res => {
			this.processEntries(res);
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// override..?
	ngOnInit(): void {
		super.ngOnInit();
		this.entries = [];
		let nModeRasRecordView: number = this.settingsService.getCurrentRasRecordsDefaultView();
		switch(nModeRasRecordView) {
			case this.settingsService.enumRasAufzeichnungDefaultFilter_AlleDaten:
				this.getAllEntries();
				break;
			
			case this.settingsService.enumRasAufzeichnungDefaultFilter_AufzeichnungenMitAkte:
				this.getFilteredEntries({hat_akte_ex: 1});
				break;

			case this.settingsService.enumRasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte:
				this.getFilteredEntries({hat_akte_ex: 0});
				break;

			case this.settingsService.enumRasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen:
				this.openFilterDialog();
				break;
				
			default:
			case this.settingsService.enumRasAufzeichnungDefaultFilter_KeineDaten:
				// nothing TODO ;)
				break;
		}
	}

	// override..?
	//ngOnInit(): void {
	//	this.loading += 1;
	//
	//	// Nutzerberechtigungen abfragen
	//	this.createPermission = this.accountService.checkPermissions(this.module, Operation.CREATE);
	//	this.readPermission = this.accountService.checkPermissions(this.module, Operation.READ);
	//	this.updatePermission = this.accountService.checkPermissions(this.module, Operation.UPDATE);
	//	this.deletePermission = this.accountService.checkPermissions(this.module, Operation.DELETE);
	//
	//	// Key des States auf apiUrl generieren
	//	this.stateName = 'state' + this.apiUrl + 'List';
	//
	//	if (this.readPermission) {
	//		if (this.hasFilterDialog) {
	//			const storage = this.storageGetEntries();
	//			if (storage && storage.timestamp && storage.entries && storage.filters) {
	//				this.loading += 1;
	//				const storedTimestamp = new Date(storage.timestamp);
	//				this.loadTimestamp = storedTimestamp;
	//				this.loadFilters = storage.filters;
	//				this.processEntries(storage.entries);
	//				this.crudService.checkPersistence(this.apiUrl, storedTimestamp).then(isPersistent => {
	//					if (!isPersistent) {
	//						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
	//					}
	//				}).catch(err => {
	//					err.error.forEach(e => {
	//						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
	//					})
	//				}).finally(() => {
	//					this.loading -= 1;
	//				});
	//			} else {
	//				if ((localStorage.getItem('defaultOpenVehicleDialog') === 'true')) {
	//					this.openFilterDialog();
	//				}
	//			}
	//		} else {
	//			this.getAllEntries();
	//		}
	//	}
	//
	//	this.retrieveTableState(this.state);
	//	if (this.state && this.state.columnOrder) {
	//		this.cols = [];
	//		this.state.columnOrder.forEach(col => {
	//			this.possibleCols.forEach(c => {
	//				if (col == c.key) {
	//					this.cols.push(c);
	//				}
	//			});
	//		});
	//	}
	//	if (this.state && this.state.filters && this.state.filters.global) {
	//		this.globalFilter = this.state.filters.global.value;
	//	}
	//
	//	this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';
	//
	//	this.initContextMenu();
	//
	//	this.filters = this.cols.map(c => c.key);
	//
	//	this.customInit();
	//
	//	this.loading -= 1;
	//}
}
