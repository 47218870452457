import { Component, OnInit } from '@angular/core';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './settings.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService]
})
export class SettingsComponent implements OnInit {
	public colors: any;
	public entryCounts: any[];
	private finishedSaving = true;
	public languages: any[];
	public loading = false;
	public maxEntries: any;
	public pages: any[];
	public selectedLandingPage: any;
	public selectedLanguage: any;
	public rasAufzeichnungDefaultFilter: any[];
	public selectedRasAufzeichnungDefaultFilter: any;
	showPostalInObjectDropdown: boolean;

	constructor(
		public app: AppMainComponent,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		private settingsService: SettingsService,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.EINSTELLUNGEN' },
			{ label: 'MENU.ALLGEMEIN', routerLink: ['/' + PATH.SETTINGS] }
		]);
	}

	safeToIntEx(val: any): number {
		let result: number = 0;
		if(val) {
			try {
				result = this.safeToInt(val.toString());
			} catch {
				result = 0;
			}
		}
		return result;
	}

	safeToInt(val: string): number {
		let result: number = 0;
		try {
			result = parseInt(val);
		} catch {
			result = 0;
		}
		return result;
	}

	ngOnInit() {
		this.languages = [
			{ label: 'Deutsch', value: { id: 1, name: 'Deutsch', code: 'DE' } },
			{ label: 'English', value: { id: 1, name: 'English', code: 'EN' } },
		];

		this.pages = this.settingsService.getPageSelection();
		this.rasAufzeichnungDefaultFilter = this.settingsService.getRasAufzeichnungDefaultFilterSelection();

		this.entryCounts = [
			{ label: '10', value: 10 },
			{ label: '50', value: 50 },
			{ label: '100', value: 100 },
			{ label: '500', value: 500 },
			{ label: '1000', value: 1000 },
			{ label: '5000', value: 5000 },
			{ label: '10000', value: 10000 },
			{ label: '50000', value: 50000 },
			{ label: '100000', value: 100000 },
		];

		this.languages.forEach(lang => {
			if (lang.value.code.toLowerCase() === this.translate.currentLang) {
				this.selectedLanguage = lang.value;
			}
		});

		this.maxEntries = +localStorage.getItem('maxEntries');
		this.selectedLandingPage = localStorage.getItem('landingPage');
		this.selectedRasAufzeichnungDefaultFilter = this.safeToInt(localStorage.getItem('rasAufzeichnungDefaultFilter')); // das muss konvertiert werden zu number, damit das dropdown auch indiziert
		//console.log(this.selectedLandingPage);
		//console.log(this.selectedRasAufzeichnungDefaultFilter);

		this.colors = [
			{ name: 'Blue', file: 'blue', color: '#5e81ac' },
			{ name: 'Brown', file: 'brown', color: '#BE9B7F' },
			{ name: 'Cyan', file: 'cyan', color: '#88C0D0' },
			{ name: 'Green', file: 'green', color: '#A3BE8C' },
			{ name: 'Indigo', file: 'indigo', color: '#8886F2' },
			{ name: 'Lime', file: 'lime', color: '#B9BE7F' },
			{ name: 'Magenta', file: 'magenta', color: '#BD69AE' },
			{ name: 'Orange', file: 'orange', color: '#D08770' },
			{ name: 'Purple', file: 'purple', color: '#B48EAD' },
			{ name: 'Red', file: 'red', color: '#F28686' },
			{ name: 'Teal', file: 'teal', color: '#88D0BD' },
			{ name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
		];

		this.showPostalInObjectDropdown = (localStorage.getItem('showPostalInObjectDropdown') === 'true');
	}

	changeComponentTheme(event, color) {
		this.app.themeColor = color;
		this.changeStyleSheet('theme-css', 'theme', 'theme-' + (this.app.darkMode === true ? 'dark' : 'light'), color);
		this.changeStyleSheet('layout-css', 'layout/css', 'layout-' + (this.app.darkMode === true ? 'dark' : 'light'), color);
		localStorage.setItem('themeColor', color);
		this.sendSetting({ Name: 'themeColor', Value: color });
		event.preventDefault();
	}

	changeDefaultLiveRouteColor() {
		localStorage.setItem('defaultLiveRouteColor', this.app.defaultLiveRouteColor);
		this.sendSetting({ Name: 'defaultLiveRouteColor', Value: this.app.defaultLiveRouteColor });
	}

	changeDefaultOldRouteColor() {
		localStorage.setItem('defaultOldRouteColor', this.app.defaultOldRouteColor);
		this.sendSetting({ Name: 'defaultOldRouteColor', Value: this.app.defaultOldRouteColor });
	}

	changeDefaultOpenVehicleDialog() {
		localStorage.setItem('defaultOpenVehicleDialog', String(this.app.defaultOpenVehicleDialog));
		this.sendSetting({ Name: 'defaultOpenVehicleDialog', Value: this.app.defaultOpenVehicleDialog });
	}

	changeDefaultRouteVisibility() {
		localStorage.setItem('defaultShowRoutes', String(this.app.defaultShowRoutes));
		this.sendSetting({ Name: 'defaultShowRoutes', Value: this.app.defaultShowRoutes });
	}

	changeForwardingColor() {
		localStorage.setItem('forwardingColor', this.app.forwardingColor);
		this.sendSetting({ Name: 'forwardingColor', Value: this.app.forwardingColor });
	}

	changeLayout(event, mode) {
		this.app.darkMode = mode;
		this.app.menuColorMode = (mode ? 'dark' : 'light');
		this.app.menuColor = 'layout-menu-' + (mode ? 'dark' : 'light');
		this.changeStyleSheet('layout-css', 'layout/css', 'layout-' + (mode ? 'dark' : 'light'), this.app.layoutColor);
		this.changeStyleSheet('theme-css', 'theme', 'theme-' + (mode ? 'dark' : 'light'), this.app.themeColor);
		localStorage.setItem('darkMode', String(mode));
		this.sendSetting({ Name: 'darkMode', Value: String(mode) });
		event.preventDefault();
	}

	changeReversingColor() {
		localStorage.setItem('reversingColor', this.app.reversingColor);
		this.sendSetting({ Name: 'reversingColor', Value: this.app.reversingColor });
	}

	changeShowAreas() {
		localStorage.setItem('defaultShowAreas', String(this.app.defaultShowAreas));
		this.sendSetting({ Name: 'defaultShowAreas', Value: this.app.defaultShowAreas });
	}

	changeShowClients() {
		localStorage.setItem('showClients', String(this.app.showClients));
		this.sendSetting({ Name: 'showClients', Value: this.app.showClients });
	}

	changeShowCollections() {
		localStorage.setItem('defaultShowCollections', String(this.app.defaultShowCollections));
		this.sendSetting({ Name: 'defaultShowCollections', Value: this.app.defaultShowCollections });
	}

	changeShowPostalInObjectDropdown() {
		localStorage.setItem('showPostalInObjectDropdown', this.showPostalInObjectDropdown ? 'true' : 'false');
	}

	changeShowTimeline() {
		localStorage.setItem('defaultShowTimeline', String(this.app.defaultShowTimeline));
		this.sendSetting({ Name: 'defaultShowTimeline', Value: this.app.defaultShowTimeline });
	}

	changeStyleSheet(id, module, mode, color) {
		/* const element = document.getElementById(id);
		const urlTokens = element.getAttribute('href').split('/');
		urlTokens[urlTokens.length - 2] = color;
		urlTokens[urlTokens.length - 1] = mode + '.css';
		const newURL = urlTokens.join('/');
		this.replaceLink(element, newURL); */

		const head = document.getElementsByTagName('head')[0];

		let themeLink = document.getElementById(id) as HTMLLinkElement;
		if (themeLink) {
			themeLink.href = `assets/${module}/${color}/${mode}.css`;
		} else {
			const style = document.createElement('link');
			style.id = id;
			style.rel = 'stylesheet';
			style.type = 'text/css';
			style.href = `assets/${module}/${color}/${mode}.css`;
			head.appendChild(style);
		}
	}

	getFromStorage(key) {
		return localStorage.getItem(key);
	}

	isIE() {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
	}

	replaceLink(linkElement, href) {
		if (this.isIE()) {
			linkElement.setAttribute('href', href);
		}
		else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		}
	}

	sendSetting(setting) {
		if (this.finishedSaving) {
			this.finishedSaving = false;
			this.loading = true;

			this.settingsService.editSetting(setting).then(res => {
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading = false;
				this.finishedSaving = true;
			});
		}
	}

	setInputStyle(event, value: string) {
		this.app.inputStyle = value;
		localStorage.setItem('inputStyle', value);
		this.sendSetting({ Name: 'inputStyle', Value: value });
		event.preventDefault();
	}

	setFontSize(value: string) {
		localStorage.setItem('fontSize', value);
		this.sendSetting({ Name: 'fontSize', Value: value });
	}

	setMenuHorizontal(event, value: boolean) {
		this.app.horizontalMenu = value;
		localStorage.setItem('horizontalMenu', String(value));
		this.sendSetting({ Name: 'horizontalMenu', Value: String(value) });
		event.preventDefault();
	}

	setTimelineAccuracy(value: string) {
		localStorage.setItem('timelineAccuracy', value);
		this.sendSetting({ Name: 'timelineAccuracy', Value: value });
	}

	switchLandingPage(): void {
		localStorage.setItem('landingPage', this.selectedLandingPage);
		this.sendSetting({ Name: 'landingPage', Value: this.selectedLandingPage });
	}

	switchRasAufzeichnungDefaultFilter(): void {
		localStorage.setItem('rasAufzeichnungDefaultFilter', this.selectedRasAufzeichnungDefaultFilter);
		this.sendSetting({ Name: 'rasAufzeichnungDefaultFilter', Value: this.selectedRasAufzeichnungDefaultFilter });
	}

	changeRestoreLastPageOnOpen(): void {
		//console.log(this.app.restoreLastPageOnOpen);
		//console.log('String(this.app.restoreLastPageOnOpen): ' + String(this.app.restoreLastPageOnOpen));
		localStorage.setItem('restoreLastPageOnOpen', String(this.app.restoreLastPageOnOpen));
		this.sendSetting({ Name: 'restoreLastPageOnOpen', Value: this.app.restoreLastPageOnOpen });
	}

	switchLanguage(): void {
		const lang = this.selectedLanguage.code.toLowerCase();
		this.translate.use(lang);
		localStorage.setItem('lang', lang);
		this.sendSetting({ Name: 'lang', Value: lang });
	}

	switchMaxEntries(): void {
		localStorage.setItem('maxEntries', this.maxEntries);
		this.sendSetting({ Name: 'maxEntries', Value: this.maxEntries });
	}
}
